<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-09-25 10:27:41
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-25 16:16:58
 * @FilePath: /mediatom-web/src/components/AccountModal/CommonForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="account-common-form-container">
    <!-- mid -->
    <a-form-model-item
      :label="midLabel(currentPlat.id)"
      prop="mid"
      v-if="showMid(currentPlat.id)"
      :rules="[{ required: midRequired(currentPlat.id), message: `请输入` + midLabel(currentPlat.id), trigger: 'blur' }]"
    >
      <a-input :placeholder="`请输入` + midLabel(currentPlat.id)" v-model.trim="formquery.mid"> </a-input>
    </a-form-model-item>
    <!-- 密钥ID -->
    <a-form-model-item
      :rules="[{ required: true, message: `请输入${getKeyIdLabel(currentPlat.id)}`, trigger: 'blur' }]"
      :label="getKeyIdLabel(currentPlat.id)"
      prop="keyId"
      v-if="showKeyId(currentPlat.id)"
    >
      <a-input :placeholder="`请输入${getKeyIdLabel(currentPlat.id)}`" v-model.trim="formquery.keyId"> </a-input>
    </a-form-model-item>
    <!-- signKey -->
    <a-form-model-item
      :rules="[
        {
          required: !(+currentPlat.id === 1002),
          message: `请输入` + getSignKeyLabel(currentPlat.id),
          trigger: 'blur'
        }
      ]"
      :label="getSignKeyLabel(currentPlat.id)"
      prop="signKey"
      v-if="getShowSignKey(currentPlat.id, formquery.isDebug)"
    >
      <a-input :placeholder="`请输入` + getSignKeyLabel(currentPlat.id)" v-model.trim="formquery.signKey"> </a-input>
    </a-form-model-item>
    <!-- 请求地址 -->
    <a-form-model-item
      label="请求地址"
      prop="requestUrl"
      v-if="showRequestUrl(currentPlat.id, formquery)"
      :rules="[{ required: true, message: '请输入请求地址', trigger: 'blur' }]"
    >
      <a-input placeholder="请输入请求地址" v-model.trim="formquery.requestUrl"> </a-input>
    </a-form-model-item>
    <!-- accountId -->
    <a-form-model-item
      v-if="getshowUserId(currentPlat.id, formquery.openApiStatus, formquery.isAutoCreate)"
      prop="accountId"
      :rules="[{ required: true, message: '请输入' + getUserIdLabel(currentPlat.id), trigger: 'blur' }]"
    >
      <m-tips
        v-if="+currentPlat.id === 3"
        slot="label"
        content="使用快手服务端实时竞价，需要填写快手联盟主账户的账户ID，可联系快手商务获取"
        :title="getUserIdLabel(currentPlat.id)"
        :width="200"
      ></m-tips>
      <span v-else slot="label">{{ getUserIdLabel(currentPlat.id) }}</span>
      <a-input :placeholder="`请输入` + getUserIdLabel(currentPlat.id)" v-model.trim="formquery.accountId"> </a-input>
      <a-alert
        v-if="+currentPlat.id === 3"
        style="font-size: 12px; margin-top: 10px; border-radius: 5px"
        message="注意：使用快手联盟服务端竞价（S2S Bidding），必需填写快手联盟主账号ID。"
        banner
      />
    </a-form-model-item>
    <!-- accessKey -->
    <a-form-model-item
      v-if="getShowAccessKey(currentPlat.id, formquery.isAutoCreate, formquery.openApiStatus, formquery.isDebug, formquery)"
      :label="getAccessKeyLabel(currentPlat.id)"
      prop="accessKey"
      :rules="[
        {
          required: +currentPlat.id === 989 ? false : true,
          message: '请输入' + getAccessKeyLabel(currentPlat.id),
          trigger: 'blur'
        }
      ]"
    >
      <a-input :placeholder="`请输入` + getAccessKeyLabel(currentPlat.id)" v-model.trim="formquery.accessKey"> </a-input>
    </a-form-model-item>
    <!-- secretKey -->
    <a-form-model-item
      :label="getSecretLabel(currentPlat.id)"
      prop="secretKey"
      v-if="getShowSecretKey(currentPlat.id, formquery.openApiStatus, formquery.isAutoCreate, formquery.isDebug)"
      :rules="[
        {
          required: +currentPlat.id === 989 ? false : true,
          message: '请输入' + getSecretLabel(currentPlat.id),
          trigger: 'blur'
        }
      ]"
    >
      <a-input :placeholder="'请输入' + getSecretLabel(currentPlat.id)" v-model.trim="formquery.secretKey"> </a-input>
      <a-alert
        v-if="+currentPlat.id === 990"
        style="font-size: 12px; margin: 10px 0"
        message="请求优量汇API接口，需填写优量汇后台生成的Media API密钥；如不填写，则无广告填充"
        banner
      />
    </a-form-model-item>
  </div>
</template>

<script>
import { midLabel, midRequired, showMid, getKeyIdLabel, showKeyId, getSignKeyLabel, getShowSignKey, showRequestUrl, getshowUserId, getUserIdLabel, getShowAccessKey, getAccessKeyLabel, getSecretLabel, getShowSecretKey } from '@/utils/autoLabel'
import moment from 'moment'
import mixDate from '@/mixins/initDate'
import { mapState } from 'vuex'
export default {
  name: 'AccountCommonForm',
  mixins: [mixDate],
  data () {
    return {}
  },
  created () {
    this.$set(this.formquery, 'mid', this.value.mid || undefined)
    this.$set(this.formquery, 'keyId', this.value.keyId || undefined)
    this.$set(this.formquery, 'signKey', this.value.signKey || undefined)
    this.$set(this.formquery, 'requestUrl', this.value.requestUrl || undefined)
    this.$set(this.formquery, 'accountId', this.value.accountId || undefined)
    this.$set(this.formquery, 'accessKey', this.value.accessKey || undefined)
    this.$set(this.formquery, 'secretKey', this.value.secretKey || undefined)
  },
  computed: {
    formquery: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title
    })
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    currentPlat: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    moment,
    midLabel,
    midRequired,
    showMid,
    getKeyIdLabel,
    showKeyId,
    getShowSignKey,
    getSignKeyLabel,
    showRequestUrl,
    getshowUserId,
    getUserIdLabel,
    getShowAccessKey,
    getAccessKeyLabel,
    getSecretLabel,
    getShowSecretKey
  }
}
</script>

<style lang="less" scoped>
</style>
